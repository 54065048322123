import type { ReactNode } from "react";
import {
  Grid,
  FormControl,
  Typography,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";

export interface IInputLayoutProps {
  id?: string;
  label?: string | null;
  errorMessage?: string;
  helperMessage?: string;
  helper?: ReactNode;
  labelAdornment?: ReactNode;
  children: ReactNode;
  labelAdornmentFullWidth?: boolean;
  hasGroupError?: boolean;
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: theme.palette.text.hint,
    marginRight: theme.spacing(1),
  },
  labelAdornment: {
    fontSize: "0.8rem",
    fontWeight: 500,
  },
  children: {
    width: "100%",
  },
  labelContainer: {
    "&.MuiGrid-item": {
      paddingTop: 0,
    },
  },
}));

export function InputLayout({
  label,
  errorMessage,
  helperMessage,
  id,
  helper,
  children,
  labelAdornment,
  labelAdornmentFullWidth = true,
  hasGroupError = false,
}: IInputLayoutProps): JSX.Element {
  const styles = useStyles();
  const hasError = errorMessage !== undefined;

  let message = helperMessage;

  if (errorMessage) {
    if (Array.isArray(errorMessage)) {
      message = errorMessage.join("; ");
    } else {
      message = errorMessage;
    }
  }

  return (
    <FormControl fullWidth error={hasError || hasGroupError}>
      <Grid container spacing={1} direction="column">
        {(label || labelAdornment) && (
          <Grid item className={styles.labelContainer}>
            <Grid
              container
              justifyContent={
                labelAdornmentFullWidth ? "space-between" : "flex-start"
              }
            >
              {label && (
                <Grid item>
                  <Typography
                    component="label"
                    htmlFor={id}
                    className={styles.label}
                  >
                    {label}
                  </Typography>
                </Grid>
              )}
              {labelAdornment && (
                <Grid item>
                  <Typography className={styles.labelAdornment}>
                    {labelAdornment}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
        <Grid item className={styles.children}>
          {children}
        </Grid>
        {(message || helper) && (
          <Grid item>
            {message ? (
              <FormHelperText id={`${id}_description`}>
                {message}
              </FormHelperText>
            ) : (
              helper
            )}
          </Grid>
        )}
      </Grid>
    </FormControl>
  );
}
