import React, { useEffect, useMemo, useRef } from "react";
import { GridSize, InputAdornment } from "@material-ui/core";
import { FormikProps, useField, useFormikContext } from "formik";
import loadable from "@loadable/component";

import { EIcon, Icon } from "src/components/Icon";
import { INumberMaskProps } from "src/components/Input/NumberMask";
import {
  ISelectInputProps,
  ISelectOption,
} from "src/components/Input/SelectInput";
import { BasicInput, IBasicInputProps } from "src/components/Input/BasicInput";
import { IOtpInputProps } from "src/components/Input/OTP";
import { IPhoneNumberInputProps } from "src/components/Input/PhoneNumberInput";
import { ISelectableListProps } from "src/components/Input/SelectableList";
import { IAddressAutocompleteProps } from "src/components/Input/AddressAutocomplete";
import { BasicInputAddressAutocomplete } from "src/components/Input/AddressAutocomplete/BasicInputAddressAutocomplete";
import { IDatePickerProps } from "src/components/Input/DatePicker";
import {
  IBrnData,
  IBrnProps,
} from "src/components/Input/BusinessRegistrationNumber";
import {
  IAmountConfig,
  ICurrencyInputProps,
} from "src/components/Input/CurrencyInput";
import { ITextAreaInputProps } from "src/components/Input/TextAreaInput";
import { IAdvancedNumberInputProps } from "src/components/Input/AdvancedNumberInput";
import { IAddressFields } from "src/components/Input/AddressAutocomplete/types";
import {
  CheckboxInput,
  ICheckboxInputProps,
} from "src/components/Input/CheckboxInput";

const OTP = loadable(() => import("src/components/Input/OTP"));
const PasswordInput = loadable(
  () => import("src/components/Input/PasswordInput")
);
const PhoneNumberInput = loadable(
  () => import("src/components/Input/PhoneNumberInput")
);
const AddressAutocomplete = loadable(
  () => import("src/components/Input/AddressAutocomplete")
);
const SelectableList = loadable(
  () => import("src/components/Input/SelectableList")
);
const SelectInput = loadable(() => import("src/components/Input/SelectInput"));
const BusinessRegistrationNumber = loadable(
  () => import("src/components/Input/BusinessRegistrationNumber")
);
const DatePicker = loadable(() => import("src/components/Input/DatePicker"));
const CurrencyInput = loadable(
  () => import("src/components/Input/CurrencyInput")
);
const TextAreaInput = loadable(
  () => import("src/components/Input/TextAreaInput")
);

const AdvancedNumberInput = loadable(
  () => import("src/components/Input/AdvancedNumberInput")
);
const NumberMask = loadable(() => import("src/components/Input/NumberMask"));

export enum EInputType {
  TEXT = "text",
  PASSWORD = "password",
  EMAIL = "email",
  PHONENUMBER = "phoneNumber",
  CODE = "code",
  SELECTABLE_LIST = "selectableList",
  SELECT = "select",
  ADDRESS_AUTOCOMPLETE = "address",
  DATE_PICKER = "datePicker",
  BUSINESS_DATA_LOOKUP = "businessDataLookup",
  AMOUNT = "amount",
  TEXT_AREA = "textarea",
  ADVANCED_NUMBER = "advancedNumber",
  NUMBER_MASK = "numberMask",
  FIELD_ARRAY = "fieldArray",
  FIELDS_ARRAY = "fieldsArray",
  CHECKBOX = "checkbox",
}

type TInputConfig<P> = Omit<P, "onChange" | "value">;

export type TFormFieldProps =
  | {
      id: string;
      label?: string;
      inputStartIcon?: EIcon | React.ReactNode;
      disabled?: boolean;
      visible?: (formik: FormikProps<unknown>) => boolean;
      gridSize?: GridSize;
    } & (
      | ({
          type: EInputType.TEXT | EInputType.EMAIL | EInputType.PASSWORD;
          onChange?: (value: string) => void;
        } & TInputConfig<IBasicInputProps>)
      | ({
          type: EInputType.ADDRESS_AUTOCOMPLETE;
          scriptData: {
            scriptLoaded: boolean;
            setScriptLoaded: (status: boolean) => void;
          };
          switchToManualMode: () => void;
          onChange?: (value: string | IAddressFields) => void;
        } & TInputConfig<IAddressAutocompleteProps>)
      | ({
          type: EInputType.PHONENUMBER;
          onChange?: (value: string) => void;
        } & TInputConfig<IPhoneNumberInputProps>)
      | ({
          type: EInputType.CODE;
          onChange?: (value: string) => void;
        } & TInputConfig<IOtpInputProps>)
      | ({
          type: EInputType.SELECTABLE_LIST;
          multiple?: boolean;
          onChange?: (value: string) => void;
        } & TInputConfig<ISelectableListProps>)
      | ({
          type: EInputType.SELECT;
          data: ISelectOption[];
          multiple?: never | false;
          onChange?: (value: string) => void;
        } & TInputConfig<ISelectInputProps>)
      | ({
          type: EInputType.SELECT;
          data: ISelectOption[];
          multiple: true;
          onChange?: (value: string[]) => void;
        } & TInputConfig<ISelectInputProps>)
      | ({
          type: EInputType.DATE_PICKER;
          onChange?: (value: string) => void;
        } & TInputConfig<IDatePickerProps>)
      | ({
          type: EInputType.BUSINESS_DATA_LOOKUP;
          onChange?: (value: IBrnData) => void;
        } & TInputConfig<IBrnProps>)
      | ({
          type: EInputType.AMOUNT;
          config: IAmountConfig;
          onChange?: (value: string) => void;
        } & TInputConfig<IDatePickerProps>)
      | ({
          type: EInputType.TEXT_AREA;
          minRow?: number;
          maxRow?: number;
          onChange?: (value: string) => void;
        } & TInputConfig<ITextAreaInputProps>)
      | ({
          type: EInputType.ADVANCED_NUMBER;
          onChange?: (value: number) => void;
        } & TInputConfig<IAdvancedNumberInputProps>)
      | ({
          type: EInputType.NUMBER_MASK;
          onChange?: (value: string) => void;
        } & TInputConfig<INumberMaskProps>)
      | ({
          type: EInputType.CHECKBOX;
          onChange?: (value: boolean) => void;
        } & TInputConfig<ICheckboxInputProps>)
    );

export function FormField(field: TFormFieldProps): JSX.Element {
  // only id is needed here, and not destructuring is beneficial
  // eslint-disable-next-line react/destructuring-assignment
  const [formikField, formikFieldMeta, fieldHelpers] = useField(field.id);
  const formContext = useFormikContext();
  const { onChange: fieldOnChange } = field;
  const prevFieldValueRef = useRef(formikField.value);

  useEffect(() => {
    if (fieldOnChange && prevFieldValueRef.current !== formikField.value) {
      (fieldOnChange as (v: string) => void)(formikField.value);
    }
  }, [formikField.value, fieldOnChange]);

  useEffect(() => {
    prevFieldValueRef.current = formikField.value;
  });

  const inputProps = useMemo(() => {
    const errorMessage =
      formikFieldMeta.touched || formContext.submitCount > 0
        ? formikFieldMeta.error
        : undefined;
    const onChange = fieldHelpers.setValue;

    let placeholder;
    if (field.type === EInputType.EMAIL) {
      placeholder = "email@example.com";
    }

    let startIcon;
    if (field.inputStartIcon) {
      if (typeof field.inputStartIcon === "function") {
        startIcon = field.inputStartIcon;
      } else {
        startIcon = (
          <InputAdornment position="start">
            <Icon icon={field.inputStartIcon as EIcon} alt="" />
          </InputAdornment>
        );
      }
    }
    let newValue = formikField.value;
    if (field.type === EInputType.SELECT) {
      if (
        !field.data.map((item) => item.value).includes(newValue) &&
        !field.multiple
      ) {
        newValue = "";
      }
    }

    return {
      placeholder,
      ...formikField,
      startAdornment: startIcon,
      ...field,
      value: newValue,
      disabled: formContext.isSubmitting || field.disabled,
      errorMessage,
      onChange,
    };
  }, [field, formikField, formikFieldMeta, fieldHelpers, formContext]);

  switch (field.type) {
    case EInputType.PASSWORD:
      return (
        <PasswordInput
          {...inputProps}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    case EInputType.PHONENUMBER:
      return (
        <PhoneNumberInput
          {...(inputProps as IPhoneNumberInputProps)}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    case EInputType.CODE:
      return (
        <OTP
          {...(inputProps as IOtpInputProps)}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    case EInputType.SELECTABLE_LIST:
      return (
        <SelectableList
          {...(inputProps as ISelectableListProps)}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    case EInputType.SELECT:
      return (
        <SelectInput
          {...(inputProps as ISelectInputProps)}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    case EInputType.ADDRESS_AUTOCOMPLETE:
      return (
        <AddressAutocomplete
          {...(inputProps as IAddressAutocompleteProps)}
          fallback={
            <BasicInputAddressAutocomplete {...inputProps} value="" disabled />
          }
        />
      );
    case EInputType.DATE_PICKER:
      return (
        <DatePicker
          {...(inputProps as IDatePickerProps)}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    case EInputType.BUSINESS_DATA_LOOKUP:
      return (
        <BusinessRegistrationNumber
          {...(inputProps as IBrnProps)}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    case EInputType.AMOUNT: {
      return (
        <CurrencyInput
          {...(inputProps as ICurrencyInputProps)}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    }
    case EInputType.TEXT_AREA: {
      return (
        <TextAreaInput
          {...(inputProps as ITextAreaInputProps)}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    }
    case EInputType.ADVANCED_NUMBER: {
      return (
        <AdvancedNumberInput
          {...(inputProps as IAdvancedNumberInputProps)}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    }
    case EInputType.NUMBER_MASK:
      return (
        <NumberMask
          {...(inputProps as INumberMaskProps)}
          fallback={<BasicInput {...inputProps} value="" disabled />}
        />
      );
    case EInputType.CHECKBOX:
      return <CheckboxInput {...(inputProps as ICheckboxInputProps)} />;
    default:
      return <BasicInput {...inputProps} />;
  }
}
