import format from "date-fns/fp/format";

export const formatDate = format("dd MMM yyyy");
export const formatDateWithoutTZ = (unformattedDate: Date | number): string => {
  const date: Date =
    typeof unformattedDate === "number"
      ? new Date(unformattedDate)
      : unformattedDate;
  const dateWithoutTimezone = new Date(
    date.valueOf() + date.getTimezoneOffset() * 60 * 1000
  );
  return formatDate(dateWithoutTimezone);
};
