import { FormikConfig, FormikValues } from "formik";

import { validateRequired } from "./validateRequired";
import { REQUIRED_MESSAGE } from "./const";

import { EInputType } from "src/components/Form/FormSection/FormGroup/FormRow/FormField";
import { IBrnData } from "src/components/Input/BusinessRegistrationNumber";
import type { IAddressFormFields } from "src/hooks/form/useAddressFormFields";
import { formatDate } from "src/utilities/formatDate";

export type TValidatorTestFunc<
  Value = unknown,
  Values extends FormikValues = FormikValues
> = (
  value: Value,
  values: Values
) => string | null | unknown[] | Promise<string | null | unknown[]>;

export type TFormValidationSchemas<Values extends FormikValues = FormikValues> =
  Record<
    string,
    {
      required?: boolean;
      multiple?: never;
      isSectionError?: boolean;
    } & (
      | {
          type:
            | EInputType.EMAIL
            | EInputType.PASSWORD
            | EInputType.PHONENUMBER
            | EInputType.CODE
            | EInputType.SELECT;
          validationTests?: TValidatorTestFunc<string, Values>[];
        }
      | {
          type: EInputType.DATE_PICKER;
          validationTests?: TValidatorTestFunc<string, Values>[];
          min?: Date;
          max?: Date;
        }
      | {
          type: EInputType.CHECKBOX;
          validationTests?: TValidatorTestFunc<boolean, Values>[];
        }
      | {
          type: EInputType.BUSINESS_DATA_LOOKUP;
          validationTests?: TValidatorTestFunc<IBrnData, Values>[];
        }
      | {
          type: EInputType.TEXT | EInputType.TEXT_AREA | EInputType.NUMBER_MASK;
          minLength?: number;
          maxLength?: number;
          validationTests?: TValidatorTestFunc<string, Values>[];
        }
      | {
          type: EInputType.ADDRESS_AUTOCOMPLETE;
          validationTests?: TValidatorTestFunc<IAddressFormFields, Values>[];
        }
      | {
          type: EInputType.AMOUNT | EInputType.ADVANCED_NUMBER;
          validationTests?: TValidatorTestFunc<number, Values>[];
        }
      | {
          type: EInputType.SELECTABLE_LIST;
          validationTests?: TValidatorTestFunc<string, Values>[];
          multiple?: never | false;
        }
      | {
          type: EInputType.SELECTABLE_LIST;
          multiple: true;
          validationTests?: TValidatorTestFunc<string[], Values>[];
        }
      | {
          type: EInputType.FIELD_ARRAY;
          validationTests?: TValidatorTestFunc<string | number[], Values>[];
        }
      | {
          type: EInputType.FIELDS_ARRAY;
          validationTests?: TValidatorTestFunc<
            Record<string, unknown>[],
            Values
          >[];
          validation?: TFormValidationSchemas<FormikValues>;
        }
    )
  >;

export function validationBuilder<Values extends FormikValues = FormikValues>(
  formValidationSchemas: TFormValidationSchemas<Values>
): NonNullable<FormikConfig<Values>["validate"]> {
  return async function validate(values, schema = formValidationSchemas) {
    const allErrors = Object.fromEntries(
      (
        await Promise.all(
          Object.entries(schema).map(async ([id, formValidationSchema]) => {
            const {
              required,
              type,
              validationTests = [],
            } = formValidationSchema;
            const value = values[id];

            if (required && !validateRequired(value, type)) {
              if (type === EInputType.DATE_PICKER) {
                // Note: this is a quick fix due to safari default date issue.
                return [id, "Please select a date"];
              }
              return [id, REQUIRED_MESSAGE];
            }

            if (
              type === EInputType.TEXT &&
              formValidationSchema.maxLength &&
              value.length > formValidationSchema.maxLength
            ) {
              return [
                id,
                `Must be at most ${formValidationSchema.maxLength} characters`,
              ];
            }
            if (type === EInputType.DATE_PICKER) {
              if (value) {
                const formattedValue = new Date(value).setHours(0, 0, 0, 0);

                if (
                  "max" in formValidationSchema &&
                  formValidationSchema.max &&
                  formattedValue >
                    new Date(formValidationSchema.max).setHours(0, 0, 0, 0)
                ) {
                  return [
                    id,
                    `Must be at most ${formatDate(formValidationSchema.max)}`,
                  ];
                }

                if (
                  "min" in formValidationSchema &&
                  formValidationSchema.min &&
                  formattedValue <
                    new Date(formValidationSchema.min).setHours(0, 0, 0, 0)
                ) {
                  return [
                    id,
                    `Must be at minimum ${formatDate(
                      formValidationSchema.min
                    )}`,
                  ];
                }
              }
            }

            const errors: (string | unknown[])[] = [];

            if (
              (type === EInputType.TEXT ||
                type === EInputType.NUMBER_MASK ||
                type === EInputType.TEXT_AREA) &&
              formValidationSchema.maxLength &&
              typeof value === "string" &&
              value.length > formValidationSchema.maxLength
            ) {
              return [
                id,
                `Must be at most ${formValidationSchema.maxLength} characters`,
              ];
            }

            if (
              (type === EInputType.TEXT || type === EInputType.NUMBER_MASK) &&
              formValidationSchema.minLength &&
              !!value &&
              value.length < formValidationSchema.minLength
            ) {
              return [
                id,
                `Must be at least ${formValidationSchema.minLength} characters`,
              ];
            }

            if (
              formValidationSchema.type === EInputType.FIELDS_ARRAY &&
              formValidationSchema.validation
            ) {
              const internalErrors = await Promise.all(
                (value as unknown[]).map(async (val) => {
                  return validate(
                    val as Values,
                    formValidationSchema.validation as unknown as TFormValidationSchemas<Values>
                  );
                })
              );
              if (
                internalErrors?.some((err) => {
                  return Object.keys(err).length > 0;
                })
              ) {
                errors.push(...internalErrors);
              }
            }

            if (formValidationSchema.isSectionError) {
              return [id, "", true];
            }

            if (validationTests) {
              (
                await Promise.all(
                  validationTests.map(
                    (
                      validationTest: TValidatorTestFunc<
                        Values[keyof Values],
                        Values
                      >
                    ) => {
                      return validationTest(value, values);
                    }
                  )
                )
              ).forEach((error) => error != null && errors.push(error));
            }
            return [id, errors];
          })
        )
      ).filter(([, errors, priority]) => {
        if (priority) {
          return priority;
        }

        if (
          errors != null &&
          (Array.isArray(errors) || typeof errors === "string")
        ) {
          return errors.length !== 0;
        }

        return false;
      })
    );
    return allErrors;
  };
}
