import { ReactNode } from "react";
import { Card, CardContent } from "@material-ui/core";

interface IGroupProps {
  children: ReactNode;
  visualGroup?: boolean;
}

export function Group({ children, visualGroup }: IGroupProps): JSX.Element {
  if (visualGroup) {
    return (
      <Card variant="outlined" raised>
        <CardContent>{children}</CardContent>
      </Card>
    );
  }
  return <>{children}</>;
}
