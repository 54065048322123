import { EInputType } from "src/components/Form/FormSection/FormGroup/FormRow/FormField";
import { IBrnData } from "src/components/Input/BusinessRegistrationNumber";

/**
 * Returns true when valid.
 *
 * Valid when value is not null or
 * follows requirement by input type.
 *
 * eg. String input is not an empty string.
 */

export function validateRequired(value: unknown, type: EInputType): boolean {
  if (value == null) {
    return false;
  }
  switch (type) {
    case EInputType.EMAIL:
    case EInputType.PASSWORD:
    case EInputType.PHONENUMBER:
    case EInputType.TEXT:
    case EInputType.TEXT_AREA:
    case EInputType.CODE:
    case EInputType.SELECT:
    case EInputType.SELECTABLE_LIST:
    case EInputType.NUMBER_MASK:
    case EInputType.FIELD_ARRAY:
    case EInputType.FIELDS_ARRAY:
      if ((value as string | string[]).length === 0) {
        return false;
      }
      break;
    case EInputType.AMOUNT:
    case EInputType.ADVANCED_NUMBER:
      if (typeof value !== "number") {
        return false;
      }
      break;
    case EInputType.BUSINESS_DATA_LOOKUP:
      if (!(value as IBrnData)?.businessNumber) {
        return false;
      }
      break;
    case EInputType.ADDRESS_AUTOCOMPLETE:
      if (!value) {
        return false;
      }
      break;
    case EInputType.CHECKBOX:
      return value as boolean;
      break;
    default:
  }

  return true;
}
