import { ChangeEventHandler, useCallback } from "react";
import { OutlinedInput } from "@material-ui/core";

import { InputLayout } from "src/components/Input/InputLayout";
import { IBasicInputProps } from "src/components/Input/BasicInput";
import { addressableToString } from "src/utilities/addressableToString";

export interface IBasicInputForAddressAutocompleteProps
  extends Omit<IBasicInputProps, "onChange"> {
  onChange: (ev: { target: { value: string } }) => void;
}

export function BasicInputAddressAutocomplete({
  onChange,
  label,
  errorMessage,
  helperMessage,
  id,
  helper,
  labelAdornment,
  value,
  ...props
}: IBasicInputForAddressAutocompleteProps): JSX.Element {
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onChange(event);
    },
    [onChange]
  );
  return (
    <InputLayout
      id={id}
      label={label as string}
      errorMessage={errorMessage}
      helperMessage={helperMessage}
      helper={helper?.(props)}
      labelAdornment={labelAdornment}
    >
      <OutlinedInput
        {...props}
        id={id}
        onChange={handleChange}
        fullWidth
        color="secondary"
        value={
          value && typeof value === "object"
            ? addressableToString(value)
            : value
        }
        inputProps={{
          maxLength: 250,
          // Disable input autocomplete and autofill
          // issue: https://gist.github.com/niksumeiko/360164708c3b326bd1c8?permalink_comment_id=3925346
          autocomplete: "off",
          role: "presentation",
        }}
      />
    </InputLayout>
  );
}
