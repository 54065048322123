import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  makeStyles,
  Typography,
  Theme,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { Icon, EIcon } from "src/components/Icon";

interface ITillDialogProps {
  title?: string;
  isOpen: boolean;
  handleClose?: () => void;
  disableMobileFullscreen?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    [theme.breakpoints.up("sm")]: {
      // 12px is right padding
      right: `calc(${theme.spacing(3)} - 12px)`,
    },
    right: `calc(${theme.spacing(2)} - 12px)`,
    top: "6px",
    color: theme.palette.grey[500],
  },
}));

const TillDialog: React.FC<ITillDialogProps> = ({
  title,
  isOpen,
  handleClose,
  children,
  disableMobileFullscreen,
}) => {
  const { closeButton } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile && !disableMobileFullscreen}
    >
      {title ? (
        <DialogTitle>
          <Grid container direction="row">
            <Grid item>
              <Typography variant="h3">{title}</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
      ) : (
        // easy fix for DialogContent first-child having different padding on top
        <div />
      )}
      {handleClose && (
        <Grid item>
          <IconButton
            aria-label="close"
            className={closeButton}
            onClick={handleClose}
          >
            <Icon icon={EIcon.CLOSE} />
          </IconButton>
        </Grid>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default TillDialog;
