import {
  Button as MUIButton,
  ButtonBase as MuiButtonBase,
  ButtonProps,
  makeStyles,
  Theme,
} from "@material-ui/core";
import clsx from "clsx";

import globalTheme from "src/theme";

export type IButtonProps = ButtonProps & {
  autoWidth?: boolean;
  component?: string;
};

const useButtonStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "999rem",
    minWidth: ({ fullWidth, autoWidth }: IButtonProps) =>
      fullWidth || autoWidth ? "auto" : 300,
    minHeight: "2.75rem",
    backgroundColor: ({ color }: IButtonProps) =>
      color === "default" ? theme.palette.common.white : undefined,
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(1, 2),
    borderColor: ({ color }: ButtonProps) =>
      color === "default" ? theme.palette.divider : "transparent",
    borderStyle: "solid",
    borderWidth: "1px",
    whiteSpace: "nowrap",
    lineHeight: 1,
  },
}));

export function Button({
  color = "primary",
  variant = "contained",
  autoWidth = false,
  ...props
}: IButtonProps): JSX.Element {
  const styles = useButtonStyles({ color, variant, autoWidth, ...props });
  return (
    <MUIButton
      className={styles.root}
      color={color}
      variant={variant}
      disableElevation
      {...props}
    />
  );
}

export enum EButtonLinkFontSize {
  SM,
  MD,
  INHERIT,
}

const fontSizeMap = {
  [EButtonLinkFontSize.SM]: globalTheme.typography.body2.fontSize,
  [EButtonLinkFontSize.MD]: globalTheme.typography.body1.fontSize,
  [EButtonLinkFontSize.INHERIT]: "inherit",
};

export type IButtonLinkProps = ButtonProps & {
  fontSize?: EButtonLinkFontSize;
};

interface IButtonLinkStylesProps {
  fontSize: EButtonLinkFontSize;
}

const useButtonLinkStyles = makeStyles<Theme, IButtonLinkStylesProps>(
  (theme) => ({
    root: {
      [theme.breakpoints.down("xs")]: {
        flexShrink: 1,
      },
      ...theme.typography.button,
      color: theme.palette.primary.main,
      textTransform: "initial",
      fontSize: ({ fontSize }: { fontSize: EButtonLinkFontSize }) =>
        fontSizeMap[fontSize],
      letterSpacing: "0em",
      flexShrink: 0,
      "&:focus-visible": {
        textDecoration: "underline",
      },
      "&:hover": {
        textDecoration: "underline",
      },
    },
  })
);

export function ButtonLink({
  fontSize = EButtonLinkFontSize.MD,
  className,
  ...props
}: IButtonLinkProps): JSX.Element {
  const styles = useButtonLinkStyles({ fontSize });
  return (
    <MuiButtonBase
      disableRipple
      className={clsx(className, styles.root)}
      {...props}
    />
  );
}
