export interface IAddress {
  addressLineOne?: string | null;
  addressLineTwo?: string | null;
  city?: string | null;
  state?: string | null;
  postcode?: string | null;
  country?: string | null;
}

export function addressableToString(address: IAddress): string {
  return [
    address.addressLineTwo,
    address.addressLineOne,
    address.city,
    address.state,
    address.postcode,
    address.country,
  ]
    .filter((v) => v)
    .join(", ");
}
