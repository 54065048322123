import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

import { CanCloseBrowserTabGuard } from "src/components/Guards/CanCloseBrowserTabGuard";
import { useModal } from "src/hooks/useModal";
import { FormUnsavedChangesModal } from "src/components/Form/FormUnsavedChangesModal";

interface IFormLeaveGuardProps {
  shouldWarn: boolean;
  leaveOnReset: boolean;
  handleLeaveOnResetCancel: () => void;
  handleLeaveOnResetConfirm: () => void;
}

export const FormLeaveGuard = ({
  shouldWarn,
  leaveOnReset = false,
  handleLeaveOnResetCancel,
  handleLeaveOnResetConfirm,
}: IFormLeaveGuardProps): JSX.Element => {
  const router = useRouter();
  const [{ nextRoute, confirmed }, setNextRoute] = useState<{
    nextRoute: null | string;
    confirmed: boolean;
  }>({ nextRoute: null, confirmed: false });

  const { open, handleOpenModal, handleCloseModal } = useModal();

  useEffect(() => {
    if (leaveOnReset && shouldWarn) {
      handleOpenModal();
    }
  }, [leaveOnReset, shouldWarn, handleOpenModal]);

  useEffect(() => {
    const routeChangeStart = (url: string) => {
      if (url && shouldWarn) {
        handleOpenModal();
        setNextRoute({ nextRoute: url, confirmed: false });
        router.events.emit("routeChangeError");

        // it's used because stops the code execution without the error message on the screen
        // eslint-disable-next-line no-throw-literal
        throw "Abort route change. Please ignore this error.";
      }
    };

    const cleanUpFunction = () =>
      router.events.off("routeChangeStart", routeChangeStart);

    if (nextRoute && confirmed) {
      router.push(nextRoute, undefined, { shallow: true });
      return cleanUpFunction;
    }

    router.events.on("routeChangeStart", routeChangeStart);

    return cleanUpFunction;
  }, [shouldWarn, nextRoute, confirmed, handleOpenModal, router]);

  const handleConfirm = () => {
    handleCloseModal();
    if (leaveOnReset) {
      handleLeaveOnResetConfirm();
    } else {
      setNextRoute({ nextRoute, confirmed: true });
    }
  };

  const handleCancel = () => {
    handleCloseModal();
    if (leaveOnReset) {
      handleLeaveOnResetCancel();
    } else {
      setNextRoute({ nextRoute: null, confirmed: false });
    }
  };

  return (
    <>
      <FormUnsavedChangesModal
        open={open}
        onClose={handleCancel}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <CanCloseBrowserTabGuard blockTabClose={shouldWarn && !leaveOnReset} />
    </>
  );
};
