import { useCallback, useState } from "react";

export const useModal = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenModal = useCallback(() => setOpen(true), []);
  const handleCloseModal = useCallback(() => setOpen(false), []);

  return { open, handleOpenModal, handleCloseModal };
};
