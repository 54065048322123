import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  CheckboxProps,
  makeStyles,
  Theme,
} from "@material-ui/core";
import clsx from "clsx";

export enum EGapBetweenCheckboxAndLabel {
  SM = 0.625,
  MD = 1.25,
}

export interface ICheckboxProps extends CheckboxProps {
  label: string;
  gap?: EGapBetweenCheckboxAndLabel;
}

interface IMakeStylesProps extends CheckboxProps {
  color?: "primary" | "secondary" | "default";
  gap: EGapBetweenCheckboxAndLabel;
}

const useStyles = makeStyles<Theme, IMakeStylesProps>((theme) => ({
  wrapper: {
    minHeight: "1.5rem",
    position: "relative",
    // 9px is left padding of checkbox
    // 1.125rem is checkboxIcon width
    // 0.3125rem is padding .MuiGrid-spacing-xs-1 > .MuiGrid-item { padding: 0.3125rem; }
    // 1.25rem or 0.625rem gap between icon and label
    paddingLeft: ({ gap }) => `calc(9px + ${1.125 + 0.3125 + gap}rem)`,
    color: ({ color }) =>
      color === "primary" ? theme.palette.text.primary : undefined,
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: "inherit",
    },
  },
  checkbox: {
    position: "absolute",
    // theme.spacing(0.5) is padding .MuiGrid-spacing-xs-1 > .MuiGrid-item { padding: 0.3125rem; }
    // -11px is left margin of parent label
    // 9px is padding around of checkbox
    left: `calc(${theme.spacing(0.5)} - ${11 - 9}px)`,
    top: "-6px", // -9px for checkbox padding +3 for line height adjust
  },
}));

export function Checkbox({
  label,
  color,
  gap = EGapBetweenCheckboxAndLabel.SM,
  ...props
}: ICheckboxProps): JSX.Element {
  const { wrapper, checkbox } = useStyles({ color, gap });

  return (
    <FormControlLabel
      className={wrapper}
      control={
        <MuiCheckbox {...props} className={clsx(checkbox, props.className)} />
      }
      label={label}
    />
  );
}

export default Checkbox;
