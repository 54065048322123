import { FormControl, FormLabel, Grid, makeStyles } from "@material-ui/core";

import { FormGroup } from "./FormGroup";
import type { IFormGroupProps } from "./FormGroup";

export interface IFormSectionProps {
  title?: string;
  groups: IFormGroupProps[];
}

const useStyles = makeStyles({
  wrapper: {
    maxWidth: "100%",
  },
});

export function FormSection({ groups, title }: IFormSectionProps): JSX.Element {
  const { wrapper } = useStyles();

  return (
    <FormControl component="fieldset" fullWidth>
      <Grid container spacing={2} direction="column">
        {title && (
          <Grid item>
            <FormLabel component="legend">{title}</FormLabel>
          </Grid>
        )}
        {groups.map((group, index) => {
          return (
            <Grid item key={group.title || index} className={wrapper}>
              <FormGroup {...group} />
            </Grid>
          );
        })}
      </Grid>
    </FormControl>
  );
}
