import React, { ReactElement } from "react";
import { FormControl, FormLabel, Grid } from "@material-ui/core";
import { useFormikContext } from "formik";

import { FormRow, TFormRowFields, TFormRowJsx } from "./FormRow";
import type { TFormRowProps } from "./FormRow";
import { Group } from "./Group";

export interface IFormGroupProps {
  title?: string;
  headerJsx?: ReactElement;
  footerJsx?: ReactElement;
  rows?: TFormRowProps[];
  visualGroup?: boolean;
}

export function FormGroup({
  rows,
  title,
  headerJsx,
  footerJsx,
  visualGroup,
}: IFormGroupProps): JSX.Element {
  const formContext = useFormikContext();

  const notEmptyRows =
    rows &&
    rows
      .map((row) => {
        const { fields } = row as TFormRowFields;
        const { jsx: rowJsx } = row as TFormRowJsx;

        if (rowJsx) {
          return { title: row.title, jsx: rowJsx };
        }

        const visibleFields = fields.filter(
          (field) => !field.visible || field.visible(formContext)
        );
        return { title: row.title, fields: visibleFields };
      })
      .filter((row) => {
        const { fields } = row as TFormRowFields;
        const { jsx: rowJsx } = row as TFormRowJsx;
        if (rowJsx) {
          return rowJsx;
        }

        return fields.length !== 0;
      });

  return (
    <FormControl component="fieldset" fullWidth>
      <Group visualGroup={visualGroup}>
        <Grid container spacing={2} direction="column" wrap="nowrap">
          {title && (
            <Grid item>
              <FormLabel component="legend">{title}</FormLabel>
            </Grid>
          )}
          {headerJsx && <Grid item>{headerJsx}</Grid>}
          {notEmptyRows?.map((row, index) => {
            return (
              <Grid item key={row.title || index}>
                <FormRow {...row} />
              </Grid>
            );
          })}
          {footerJsx && <Grid item>{footerJsx}</Grid>}
        </Grid>
      </Group>
    </FormControl>
  );
}
