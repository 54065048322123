import { Grid, makeStyles, Typography } from "@material-ui/core";

import TillDialog from "src/components/TillDialog";
import { Button } from "src/components/Input/Button";

export type TFormUnsavedChangesModal = {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
};

const useStyle = makeStyles((theme) => ({
  buttonsContainer: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.spacing(25),
    },
  },
  buttonLeftWrapper: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.spacing(12),
    },
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  buttonRightWrapper: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.spacing(12),
    },
    [theme.breakpoints.down("xs")]: {
      order: 0,
    },
  },
}));

export function FormUnsavedChangesModal({
  open,
  onClose,
  onCancel,
  onConfirm,
}: TFormUnsavedChangesModal): JSX.Element {
  const { buttonsContainer, buttonLeftWrapper, buttonRightWrapper } =
    useStyle();

  return (
    <TillDialog
      isOpen={open}
      handleClose={onClose}
      title="Unsaved Changes"
      disableMobileFullscreen
    >
      <Grid container justifyContent="center" spacing={2}>
        <Grid item sm xs={12}>
          <Typography component="p" variant="body1">
            Are you sure you want to leave the page?
          </Typography>
          <Typography component="p" variant="body1">
            The information you&apos;ve entered may not be saved
          </Typography>
        </Grid>
        <Grid item sm xs={12} className={buttonsContainer}>
          <Grid container spacing={1}>
            <Grid item sm xs={12} className={buttonLeftWrapper}>
              <Button
                type="button"
                variant="outlined"
                color="default"
                fullWidth
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item sm xs={12} className={buttonRightWrapper}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                onClick={onConfirm}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TillDialog>
  );
}
