import { useEffect } from "react";

export interface ICanCloseBrowserTabGuardProps {
  blockTabClose: boolean;
}

const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  const confirmationMessage =
    "Are you sure you want to leave this page? The information you've entered may not be saved.";
  // eslint-disable-next-line no-param-reassign
  event.returnValue = confirmationMessage; // Gecko + IE
  return confirmationMessage; // Webkit, Safari, Chrome
};

// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
export function CanCloseBrowserTabGuard({
  blockTabClose,
}: ICanCloseBrowserTabGuardProps): JSX.Element {
  useEffect(() => {
    if (blockTabClose) {
      window.addEventListener("beforeunload", beforeUnloadListener);
    }
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, [blockTabClose]);

  return <></>;
}
