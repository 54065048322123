import React from "react";
import { useFormikContext } from "formik";
import { useMediaQuery, useTheme, Grid } from "@material-ui/core";

import { FormField, TFormFieldProps } from "./FormField";

export type TFormRowFields = {
  fields: TFormFieldProps[];
};

export type TFormRowJsx = {
  jsx: JSX.Element;
};

export type TFormRowProps = { title?: string } & (TFormRowFields | TFormRowJsx);

export function FormRow(props: TFormRowProps): JSX.Element {
  const formContext = useFormikContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { fields } = props as TFormRowFields;
  const { jsx } = props as TFormRowJsx;

  return (
    <Grid container spacing={2} wrap="wrap">
      {jsx && (
        <Grid item xs={12}>
          {jsx}
        </Grid>
      )}
      {fields?.map((field) => {
        return !field.visible || field.visible(formContext) ? (
          <Grid
            key={field.id}
            item
            style={{ width: "100%" }}
            zeroMinWidth
            xs={isMobile ? 12 : field?.gridSize ?? true}
          >
            <FormField {...field} />
          </Grid>
        ) : null;
      })}
    </Grid>
  );
}
