import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Formik,
  Form as FormikForm,
  useFormikContext,
  FormikContextType,
} from "formik";
import type { FormikConfig, FormikValues } from "formik";
import isEqual from "lodash.isequal";
import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import clsx from "clsx";

import { FormSection } from "./FormSection";
import type { IFormSectionProps } from "./FormSection";
import { FormLeaveGuard } from "./FormLeaveGuard";

import { Button } from "src/components/Input/Button";

function AutoSubmitter<Values>({
  autoSubmit,
}: {
  autoSubmit: (values: Values) => boolean;
}) {
  const [pastValues, setPastValues] = useState<Values>();
  const formik = useFormikContext<Values>();

  useEffect(() => {
    if (
      formik.dirty &&
      !formik.isSubmitting &&
      !formik.isValidating &&
      !isEqual(formik.values, pastValues)
    ) {
      setPastValues(formik.values);

      if (autoSubmit(formik.values)) {
        formik.submitForm();
      }
    }
  }, [formik, autoSubmit, pastValues]);

  return null;
}

export interface IFormProps<Values extends FormikValues = FormikValues>
  extends FormikConfig<Values> {
  title?: string;
  formConfig?: IFormSectionProps[];
  submitLabel?: string;
  resetLabel?: string;
  autoSubmit?: (values: Values) => boolean;
  disableSpacing?: boolean;
  warnOnUnsavedLeave?: boolean;
  setFormikContext?: (formikContext: FormikContextType<Values>) => void;
  children?: (
    formikContext: FormikContextType<Values>
  ) => ReactNode | ReactNode;
  disableFormValidationMessage?: boolean;
}

export interface IMakeStylesProps {
  number: number;
  disableSpacing?: boolean;
}

const useStyles = makeStyles<Theme, IMakeStylesProps>((theme) => ({
  formStyle: {
    textAlign: "left",

    // Known limitation: https://material-ui.com/components/grid/#negative-margin
    // (Solved in v5, expected 10/2021 https://material-ui.com/versions/#release-schedule)
    maxWidth: "100%",
    padding: ({ disableSpacing }) =>
      disableSpacing ? theme.spacing(0) : theme.spacing(3, 0),
  },
  wrapper: {
    maxWidth: "100%",
  },
  buttonWrapper: {
    width: "13.25rem",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  buttonSubmitWrapper: {
    [theme.breakpoints.down("xs")]: {
      order: 0,
    },
  },
  buttonCancelWrapper: {
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  formError: {
    fontSize: "0.875rem",
  },
}));

const defaultError =
  "Sorry, we are facing an unexpected issue. Please try again later.";

export function Form<Values extends FormikValues = FormikValues>({
  formConfig,
  children,
  title,
  initialValues = {} as Values,
  onSubmit,
  submitLabel = "Submit",
  onReset,
  resetLabel = "Reset",
  validate,
  autoSubmit,
  disableSpacing,
  warnOnUnsavedLeave = false,
  setFormikContext,
  disableFormValidationMessage,
}: IFormProps<Values>): JSX.Element {
  const {
    formStyle,
    buttonWrapper,
    buttonSubmitWrapper,
    buttonCancelWrapper,
    wrapper,
    ...styles
  } = useStyles({
    number: 1,
    disableSpacing,
  });

  const [leaveOnReset, setLeaveOnReset] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const handleSubmit: FormikConfig<Values>["onSubmit"] = async (
    values,
    helpers
  ) => {
    helpers.setStatus({
      formError: undefined,
    });

    try {
      await onSubmit(values, helpers);
      // We need this because Formik sets isSubmitting to false before navigation is done as we don't wait for router.push to resolve
      setIsSubmitted(true);
    } catch (error) {
      helpers.setStatus({
        formError: (error as Error)?.message || defaultError,
      });
    }
  };

  const handleReset: (formik: FormikContextType<Values>) => void = (formik) => {
    formik.handleReset();
    if (onReset) {
      onReset(formik.values, formik);
    }
    setLeaveOnReset(false);
  };

  const onResetClick: (formik: FormikContextType<Values>) => void = (
    formik
  ) => {
    if (formik.dirty && warnOnUnsavedLeave) {
      setLeaveOnReset(true);
    } else {
      handleReset(formik);
    }
  };

  return (
    <Formik<Values>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {(formik) => {
        if (setFormikContext) {
          setFormikContext(formik);
        }
        const formError =
          formik.status?.formError ||
          (formik.submitCount > 0 &&
            Object.values(formik.errors).length > 0 &&
            !disableFormValidationMessage &&
            "Please check the data entered");

        return (
          <FormikForm className={formStyle} noValidate>
            <Grid container spacing={isMobile ? 3 : 4} direction="column">
              {title && (
                <Grid item>
                  <Typography>{title}</Typography>
                </Grid>
              )}
              {children && (
                <Grid item>
                  {typeof children === "function" ? children(formik) : children}
                </Grid>
              )}
              {formConfig?.map((section, index) => {
                return (
                  <Grid item key={section.title || index} className={wrapper}>
                    <FormSection {...section} />
                  </Grid>
                );
              })}
              <div aria-live="assertive" aria-atomic="true">
                {formError && (
                  <Grid item>
                    <Typography
                      color="error"
                      align="center"
                      className={styles.formError}
                    >
                      {formError}
                    </Typography>
                  </Grid>
                )}
              </div>
              {autoSubmit ? (
                <AutoSubmitter autoSubmit={autoSubmit} />
              ) : (
                <Grid item>
                  <Grid
                    container
                    justifyContent="center"
                    spacing={isMobile ? 1 : 2}
                  >
                    {onReset && (
                      <Grid
                        item
                        className={clsx(buttonCancelWrapper, buttonWrapper)}
                      >
                        <Button
                          type="reset"
                          variant="contained"
                          color="default"
                          disabled={formik.isSubmitting}
                          fullWidth
                          onClick={(
                            event: SyntheticEvent<HTMLButtonElement>
                          ) => {
                            event.preventDefault();
                            onResetClick(formik);
                          }}
                        >
                          {resetLabel}
                        </Button>
                      </Grid>
                    )}

                    <Grid
                      item
                      className={clsx(buttonSubmitWrapper, buttonWrapper)}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={formik.isSubmitting}
                        fullWidth
                      >
                        {submitLabel}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {warnOnUnsavedLeave && (
              <FormLeaveGuard
                shouldWarn={
                  !formik.isSubmitting &&
                  (!isSubmitted ||
                    (isSubmitted && formik.status?.formError !== undefined)) &&
                  formik.dirty
                }
                handleLeaveOnResetConfirm={() => handleReset(formik)}
                handleLeaveOnResetCancel={() => setLeaveOnReset(false)}
                leaveOnReset={leaveOnReset}
              />
            )}
          </FormikForm>
        );
      }}
    </Formik>
  );
}
