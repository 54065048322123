import React, { ReactNode } from "react";
import { OutlinedInputProps } from "@material-ui/core";

import {
  IInputLayoutProps,
  InputLayout,
} from "src/components/Input/InputLayout";
import { Checkbox, EGapBetweenCheckboxAndLabel } from "src/components/Checkbox";
import { EIcon, Icon } from "src/components/Icon";

export interface ICheckboxInputProps
  extends Omit<OutlinedInputProps, "onChange" | "label">,
    Omit<IInputLayoutProps, "helper" | "children"> {
  id: string;
  label: string;
  value?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  helper?: (
    input: { errorMessage?: string } & Omit<
      OutlinedInputProps,
      "onChange" | "label"
    >
  ) => ReactNode;
}

export function CheckboxInput({
  id,
  label,
  value,
  errorMessage,
  helperMessage,
  helper,
  labelAdornment,
  labelAdornmentFullWidth,
  onChange,
  disabled,
  color = "primary",
  ...props
}: ICheckboxInputProps): JSX.Element {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  let icon: JSX.Element = <Icon icon={EIcon.CHECKBOX} />;
  let checkedIcon: JSX.Element = <Icon icon={EIcon.CHECKBOX_FILLED} />;

  if (disabled) {
    if (value) {
      checkedIcon = <Icon icon={EIcon.CHECKBOX_FILLED_DISABLED} />;
    } else {
      icon = <Icon icon={EIcon.CHECKBOX_DISABLED} />;
    }
  } else if (errorMessage) {
    icon = <Icon icon={EIcon.CHECKBOX_NOT_VALIDATE} />;
  }

  return (
    <InputLayout
      id={id}
      errorMessage={errorMessage}
      helperMessage={helperMessage}
      helper={helper?.(props)}
      labelAdornment={labelAdornment}
      labelAdornmentFullWidth={labelAdornmentFullWidth}
    >
      <Checkbox
        id={id}
        name={id}
        checked={value}
        onChange={handleChange}
        label={label}
        disabled={disabled}
        color={color}
        gap={EGapBetweenCheckboxAndLabel.MD}
        icon={icon}
        checkedIcon={checkedIcon}
      />
    </InputLayout>
  );
}

export default CheckboxInput;
