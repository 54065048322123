import { useCallback } from "react";
import type { ReactNode, ChangeEventHandler } from "react";
import { OutlinedInput } from "@material-ui/core";
import type { OutlinedInputProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { InputLayout, IInputLayoutProps } from "../InputLayout";

const useStyles = makeStyles({
  msDisable: {
    "&::-ms-reveal": {
      display: "none",
    },
  },
});

export interface IBasicInputProps
  extends Omit<OutlinedInputProps, "onChange" | "label">,
    Omit<IInputLayoutProps, "helper" | "children"> {
  onChange: (value: string) => void;
  helper?: (
    input: { errorMessage?: string } & Omit<
      OutlinedInputProps,
      "onChange" | "label"
    >
  ) => ReactNode;
  hasGroupError?: boolean;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export function BasicInput({
  onChange,
  label,
  errorMessage,
  helperMessage,
  id,
  helper,
  labelAdornment,
  labelAdornmentFullWidth,
  hasGroupError,
  onBlur: onFormikBlur,
  ...props
}: IBasicInputProps): JSX.Element {
  const styles = useStyles();
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <InputLayout
      id={id}
      label={label as string}
      hasGroupError={hasGroupError}
      errorMessage={errorMessage}
      helperMessage={helperMessage}
      helper={helper?.(props)}
      labelAdornment={labelAdornment}
      labelAdornmentFullWidth={labelAdornmentFullWidth}
    >
      <OutlinedInput
        {...props}
        classes={{ input: styles.msDisable }}
        id={id}
        onChange={handleChange}
        fullWidth
        color="secondary"
        onBlur={(event) => {
          // As input is controlled, using controlled value
          // as target could be different see: PhoneNumberInput
          if (typeof props.value === "string") {
            onChange(props.value.trim());
          }

          if (onFormikBlur) {
            onFormikBlur(event);
          }
        }}
      />
    </InputLayout>
  );
}
